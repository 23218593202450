<template>
  <bottom-up-modal
    :isVisible="isVisible"
    @closeModal="$emit('closeModal')"
    :title="$t('edit_receiver')"
  >
    <div>
      <!-- receiver form -->
      <div class="flex w-full flex-wrap sm:flex-nowrap gap-2">
        <input-text
          class="w-full sm:w-1/3"
          :id="'receiver_name'"
          :label="$t('designation')"
          :name="'name'"
          :labelClass="'bg-white'"
          v-model="GET_CUSTOMER_RECEIVER.name"
        />
        <input-text
          class="w-full sm:w-1/3"
          :id="'network_address'"
          :label="$t('device_id')"
          :name="' network_address'"
          :labelClass="'bg-white'"
          v-model="GET_CUSTOMER_RECEIVER.network_address"
        />
      </div>

      <!-- action buttons -->
      <div class="mt-5 flex gap-4 ">
        <custom-button
          class="h-11 w-full sm:w-1/4 ml-auto"
          :bgColor="'bg-grey-fontgrey'"
          :textColor="'text-white'"
          :text="$t('abort')"
          @click="
            $emit('closeModal');
            set_back();
          "
        />
        <custom-button
          class="h-11 w-full sm:w-1/4"
          :bgColor="'bg-primary'"
          :textColor="'text-white'"
          :text="$t('edit_2')"
          @click="UPDATE_RECEIVER()"
        />
      </div>
    </div>

    <div class="w-full h-px bg-primary-light mt-8"></div>

    <!--- Receiver container -->
    <receiver-container />
  </bottom-up-modal>
</template>

<script>
import { computed, defineComponent, reactive } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import CustomButton from "../../../components/input/CustomButton.vue";
import InputText from "../../../components/input/InputText.vue";
import BottomUpModal from "../../../components/layout/BottomUpModal.vue";

import {
  CUSTOMER_RECEIVERS_TYPES,
  HELPERS_TYPES,
  STORE_TYPES,
} from "../../../store/types";
import ReceiverContainer from "../components/ReceiverContainer.vue";

export default defineComponent({
  components: {
    BottomUpModal,
    InputText,
    CustomButton,
    ReceiverContainer,
  },
  emits: ["closeModal"],
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, ctx) {
    const store = useStore();
    const router = useRouter();
    /**
     *  Refs
     */

    /**
     *  State
     */
    const state = reactive({});

    /**
     * emits
     */

    /*
     * Computeds
     */

    const GET_CUSTOMER_RECEIVER = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_RECEIVERS}/${CUSTOMER_RECEIVERS_TYPES.GET_CUSTOMER_RECEIVER}`
      ];
    });

    /** FUNCTION */

    const UPDATE_RECEIVER = async () => {
      let receiverData = {
        id: GET_CUSTOMER_RECEIVER.value.id,
        customer_id: GET_CUSTOMER_RECEIVER.value.customer_id,
        customer_receiver: GET_CUSTOMER_RECEIVER.value,
      };

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "update"
      );

      const response = await store.dispatch(
        `${STORE_TYPES.CUSTOMER_RECEIVERS}/${CUSTOMER_RECEIVERS_TYPES.UPDATE_CUSTOMER_RECEIVER}`,
        receiverData
      );

      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "update"
      );

      if (response.status == 200) {
        ctx.emit("closeModal");
      }
    };

    const SET_CUSTOMER_RECEIVER = (receiver) => {
      return store.commit(
        `${STORE_TYPES.CUSTOMER_RECEIVERS}/${CUSTOMER_RECEIVERS_TYPES.SET_CUSTOMER_RECEIVER}`,
        receiver
      );
    };

    const set_back = () => {
      SET_CUSTOMER_RECEIVER({});
    };

    const edit_receiver = (receiver) => {
      SET_CUSTOMER_RECEIVER(receiver);
      state.openModal = true;
    };

    return {
      state,
      router,
      store,
      GET_CUSTOMER_RECEIVER,
      set_back,
      edit_receiver,
      UPDATE_RECEIVER,
    };
  },
});
</script>
