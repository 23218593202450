<template>
  <div class="font-semibold mt-8">{{ $t("receiver") }}</div>

  <!-- search input -->
  <div class="flex items-center mb-3 gap-3 w-full mt-4">
    <searchbar
      :id="'search'"
      :label="$t('search')"
      v-model="state.search"
      :labelClass="'bg-white-dark'"
      :activeColor="'primary'"
      class="w-1/3"
      :placeHolder="$t('search')"
    />
  </div>

  <div v-if="filteredReceivers != null || filteredReceivers.length > 0">
    <!--- receiver list -->
    <div
      class=" bg-white-dark rounded-lg mt-3 p-3  
      hover:border-grey-fontgrey"
      :class="
        GET_CUSTOMER_RECEIVER.id == receiver.id
          ? 'border-t-4 border-primary'
          : 'border border-transparent'
      "
      v-for="receiver of filteredReceivers"
      :key="receiver.id"
      :receiver="receiver"
    >
      <div class="flex items-center gap-2">
        <div class="font-semibold flex gap-2">
          <div>{{ receiver.name }}</div>
        </div>

        <div
          class="cursor-pointer ml-auto mr-4"
          @click="edit_receiver(receiver)"
        >
          <edit-icon />
        </div>
      </div>
      <!-- --------- -->
      <div class="text-grey-fontgrey text-sm mt-2 ">
        {{ $t("device_id") }}: {{ receiver.network_address }}
      </div>
    </div>
  </div>

  <!--empty feedback-->
  <div v-else>
    <empty-list-feedback
      class="pt-10 flex justify-center items-center"
      v-if="filteredReceivers == null || filteredReceivers.length == 0"
      :text="$t('no_receiver')"
    ></empty-list-feedback>
  </div>
</template>

<script>
import { computed, defineComponent, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import EmptyListFeedback from "../../../components/global/EmptyListFeedback.vue";
import EditIcon from "../../../components/icons/EditIcon.vue";
import Searchbar from "../../../components/layout/Searchbar.vue";
import { CUSTOMER_RECEIVERS_TYPES, STORE_TYPES } from "../../../store/types";

export default defineComponent({
  components: { EditIcon, Searchbar, EmptyListFeedback },
  emits: ["editReceiver"],
  name: "Receiver",
  props: {
    editContainer: {
      type: Boolean,
      default: false,
    },
    openModal: {
      type: Boolean,
      default: false,
    },
    receiver: {
      type: Object,
    },
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    /** State */
    const state = reactive({
      openModal: false,
      search: "",
    });

    /*
     * Computeds
     */
    const GET_CUSTOMER_RECEIVERS = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_RECEIVERS}/${CUSTOMER_RECEIVERS_TYPES.GET_CUSTOMER_RECEIVERS}`
      ];
    });
    const GET_CUSTOMER_RECEIVER = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_RECEIVERS}/${CUSTOMER_RECEIVERS_TYPES.GET_CUSTOMER_RECEIVER}`
      ];
    });

    const filteredReceivers = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_RECEIVERS}/${CUSTOMER_RECEIVERS_TYPES.GET_CUSTOMER_RECEIVERS}`
      ].filter((search) => {
        return search.name.toLowerCase().match(state.search.toLowerCase());
      });
    });
    const SET_CUSTOMER_RECEIVER = (receiver) => {
      return store.commit(
        `${STORE_TYPES.CUSTOMER_RECEIVERS}/${CUSTOMER_RECEIVERS_TYPES.SET_CUSTOMER_RECEIVER}`,
        receiver
      );
    };

    const edit_receiver = (receiver) => {
      SET_CUSTOMER_RECEIVER(receiver);
      state.openModal = true;
    };

    /** Functions */

    return {
      store,
      state,
      route,
      GET_CUSTOMER_RECEIVERS,
      GET_CUSTOMER_RECEIVER,
      filteredReceivers,
      edit_receiver,
    };
  },
});
</script>
