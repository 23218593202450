<template>
  <main class="flex flex-col items-center">
    <main-content-container>
      <h2 class="font-semibold mb-6 mt-3 font-roboto text-xlg text-black">
        {{ $t("receiver") }}
      </h2>

      <receiver />
    </main-content-container>
  </main>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import MainContentContainer from "../../components/global/MainContentContainer.vue";
import Receiver from "./show/Receiver.vue";

export default defineComponent({
  components: {
    MainContentContainer,
    Receiver,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    /*
     * State
     */

    const state = reactive({});

    /*
     *Computeds
     */

    /*
     *Functions
     */

    return { state, route, store };
  },
});
</script>
