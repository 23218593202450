<template>
  <!-- search input --->
  <div class="flex items-center gap-3 mb-4 w-full">
    <searchbar
      :id="'search'"
      :label="$t('search')"
      v-model="state.search"
      :labelClass="'bg-white-dark'"
      :activeColor="'primary'"
      class="w-full sm:w-1/3"
      :placeHolder="$t('search')"
    />
  </div>

  <!-- Content -->
  <div v-if="filteredReceivers != null && filteredReceivers.length > 0">
    <div
      class="bg-white cursor-pointer p-3 rounded-md h-auto flex-1 gap-8 mb-3 hover:border-grey-fontgrey"
      :class="
        GET_CUSTOMER_RECEIVER.id == receiver.id
          ? 'border-t-4 border-primary'
          : 'border border-transparent'
      "
      v-for="receiver of filteredReceivers"
      :key="receiver.id"
      :receiver="receiver"
      @click="edit_receiver(receiver)"
    >
      <!-- Title -->
      <div class="flex items-center gap-2" v-if="receiver != null">
        <div class="font-semibold flex gap-2">
          <div>{{ receiver.name }}</div>
        </div>

        <div
          class="ml-auto rounded-full w-10 h-10 items-center flex hover:bg-primary-lightest "
          @click.stop="edit_receiver(receiver)"
        >
          <div class="cursor-pointer ml-2"><edit-icon /></div>
        </div>
      </div>
      <!-- --------- -->

      <div class="mt-3 ">
        <div class="text-sm text-blue">
          {{ $t("device_id") }}
        </div>
        <div class="text-md">
          {{ receiver.network_address }}
        </div>
      </div>
    </div>
  </div>

  <!--empty feedback-->
  <div v-else>
    <empty-list-feedback
      class="pt-10 flex justify-center items-center"
      v-if="filteredReceivers == null || filteredReceivers.length == 0"
      :text="$t('no_receiver')"
    ></empty-list-feedback>
  </div>

  <!--edit receiver modal-->
  <edit-receiver-modal
    :isVisible="state.openModal"
    @closeModal="set_back()"
    @editReceiver="edit_receiver(receiver)"
  />
</template>

<script>
import { computed, defineComponent, reactive, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import EmptyListFeedback from "../../../components/global/EmptyListFeedback.vue";
import Searchbar from "../../../components/layout/Searchbar.vue";

import {
  AUTH_TYPES,
  CUSTOMER_RECEIVERS_TYPES,
  STORE_TYPES,
} from "../../../store/types";
// import ReceiverContainer from "../components/ReceiverContainer.vue";
import EditReceiverModal from "../modals/EditReceiverModal.vue";

export default defineComponent({
  components: {
    // ReceiverContainer,
    EditReceiverModal,
    Searchbar,
    EmptyListFeedback,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    /** State */
    const state = reactive({
      openModal: false,
      search: "",
    });

    /*
     * Computeds
     */
    const GET_CUSTOMER_RECEIVERS = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_RECEIVERS}/${CUSTOMER_RECEIVERS_TYPES.GET_CUSTOMER_RECEIVERS}`
      ];
    });

    const GET_CUSTOMER_RECEIVER = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_RECEIVERS}/${CUSTOMER_RECEIVERS_TYPES.GET_CUSTOMER_RECEIVER}`
      ];
    });

    const GET_USER = computed(
      () => store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`]
    );

    const filteredReceivers = computed(() => {
      return store.getters[
        `${STORE_TYPES.CUSTOMER_RECEIVERS}/${CUSTOMER_RECEIVERS_TYPES.GET_CUSTOMER_RECEIVERS}`
      ].filter((search) => {
        return search.name.toLowerCase().match(state.search.toLowerCase());
      });
    });

    /** Functions */

    const SET_CUSTOMER_RECEIVER = (receiver) => {
      return store.commit(
        `${STORE_TYPES.CUSTOMER_RECEIVERS}/${CUSTOMER_RECEIVERS_TYPES.SET_CUSTOMER_RECEIVER}`,
        receiver
      );
    };

    function FETCH_CUSTOMER_RECEIVERS() {
      return store.dispatch(
        `${STORE_TYPES.CUSTOMER_RECEIVERS}/${CUSTOMER_RECEIVERS_TYPES.FETCH_CUSTOMER_RECEIVERS}`,
        GET_USER.value.customer_id
      );
    }

    const edit_receiver = (receiver) => {
      SET_CUSTOMER_RECEIVER(receiver);
      state.openModal = true;
    };
    const set_back = () => {
      SET_CUSTOMER_RECEIVER({});
      state.openModal = false;
    };

    watch(
      () => GET_USER.value,
      (newValue) => {
        if (newValue != null && newValue.customer_id != null) {
          FETCH_CUSTOMER_RECEIVERS(newValue.customer_id);
        }
      },
      {
        immediate: true,
        deep: true,
      }
    );

    return {
      store,
      state,
      route,
      edit_receiver,
      set_back,
      GET_CUSTOMER_RECEIVERS,
      filteredReceivers,
      GET_CUSTOMER_RECEIVER,
    };
  },
});
</script>
